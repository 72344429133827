// import '../scss/Front/front.scss';

function fakeSelectInit() {
  $('.custom-select-contain select').each((_, select) => {
    const selectCustom = $(select);
    let selectCustomVal = selectCustom.val();
    const options = [];

    selectCustom.css('opacity', '0');

    selectCustom.find('option').each((k, option) => {
      options.push([$(option).text(), $(option).attr('value')]);

      if (selectCustomVal === $(option).attr('value')) {
        selectCustomVal = $(option).text();
      }
    });

    selectCustom.after(`<div class="fake-select"><span>${selectCustomVal}</span></div>`);

    const fakeSelect = selectCustom.parent().find('.fake-select').append('<ul></ul>');

    $.each(options, (key, value) => {
      let active = '';

      if (selectCustomVal === value[0]) {
        active = ' class="active"';
      }

      fakeSelect.find('ul').append(`<li${active}>${value[0]}</li>`);
    });

    fakeSelect.click(() => {
      const isVisible = fakeSelect.find('ul').is(':visible');

      if (!isVisible) {
        fakeSelect.find('ul').show();
      } else {
        $('.fake-select ul').hide();
      }
    });

    fakeSelect.find('li').click(e => {
      fakeSelect.find('span').text($(e.currentTarget).text());
      selectCustom.val(options[$(e.currentTarget).index()]);
      selectCustom.trigger('change');

      fakeSelect.find('li.active').removeClass('active');
      fakeSelect.find(`li:eq(${$(e.target).index()})`).addClass('active');
    });
  });
}

class MainController {
  constructor() {
    $('#navbar-collapse').on('show.bs.collapse', () => {
      $(window).scrollTop(0);
    });

    $('#dropdownFilterParent').on('shown.bs.dropdown', () => {
      $(this).find('.dropdown-toggle span').text('Fermer');
      $(this).find('.dropdown-toggle i').text('close');
    }).on('hidden.bs.dropdown', () => {
      $(this).find('.dropdown-toggle span').text('Filtrer');
      $(this).find('.dropdown-toggle i').text('filter_list');
    });

    $('#dropdownFilterParent .dropdown-menu').on('click.bs.dropdown', e => {
      e.stopPropagation();
    });
    fakeSelectInit();
  }
}

$(document).ready(() => new MainController());
